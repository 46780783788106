import { inject, Injectable } from "@angular/core";
import { ValidUrlService } from "@core/services";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { ValidURLResponse } from "@core/interfaces";
import { GlobalVarService } from '@core/services';
import { catchError, concatMap, EMPTY, first, map, mergeMap } from "rxjs";
import { GlobalAttributesInterface } from "../../interfaces";
import { selectIdEmpleado } from "./global.selectors";


@Injectable()
export class GlobalAttibutesEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly validUrlService = inject(ValidUrlService);
  private readonly GlobalVarService = inject(GlobalVarService);


  loadGlobalAttributes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Global]: Set ID Empleado'),
      mergeMap(() => {
        return this.store.select(selectIdEmpleado).pipe(
          concatMap((employeeID) => this.validUrlService.validUrl({ idAgente: employeeID })
            .pipe(
              map((response) => ({
                type: '[Global]: Loaded Global Attributes Success',
                payload: this.getGlobalAttributes(response)
              })),
              catchError(() => {
                this.GlobalVarService.showOutlet.set(false);
                return EMPTY;
              })
            )),
          first()
        )
      }
      )
    )
  });


  private getGlobalAttributes(response: ValidURLResponse): GlobalAttributesInterface {
    const [oficinaId, claveAlmacen] = response.clv_ofi_alm.split('-');

    return {
      useDesktop: true,
      flujo: response.flujoDesc,
      idFlujo: response.idFlujo,
      region: response.idRegion,
      regionDat: response.regionDat || undefined,
      oficinaId,
      claveAlmacen,
      claveOficinaAlmacen: response.clv_ofi_alm,
      claveSap: response.claveSap,
      canal: response.canal,
      idEmpleado: response.idAgente,
      fuerzaVenta: response.fzaVenta,
      agente: response.nombreAgente,
      agenteDAT: response.agenteDAT == null ? undefined : response.agenteDAT,
      idCanal: response.idCanal,
      oficinaDescripcion: response.descOficina
    }
  }
}
